/* You can add global styles to this file, and also import other style files */
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import './theme.scss';

html,
body {
  height: 100vh;
}
body {
  margin: 0;
  // CAUTION: this is also in the theme.scss file
  font-family: 'Rubik', 'Helvetica Neue', sans-serif;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #ffce32;
}

// For form fields on primary colored background (required for text visibility)
.mat-form-field.primary-background {
  .mat-form-field-label,
  .mat-select-value,
  .mat-select-arrow,
  .mat-input-element {
    color: white !important;
    caret-color: white !important;
  }

  .mat-form-field-ripple,
  .mat-form-field-underline {
    background-color: white !important;
  }
}

// for form fileds with no underline
.mat-form-field.no-line {
  .mat-form-field-underline {
    visibility: hidden;
  }
}

// for form fileds with solid underline while
.mat-form-field.readonly {
  .mat-form-field-underline {
    background: white !important;
  }
}

// for form fileds select with no drowdown arrow
.mat-form-field.no-arrow {
  .mat-select-arrow-wrapper {
    visibility: hidden;
  }
}

// for form fileds select with no value
.mat-form-field.no-value {
  .mat-select-value {
    color: transparent;
  }
}

// for warning red error snackbar
.mat-snack-bar-container.error-snack {
  background-color: #f44336;
}

// for maximal width of adding photo to concern
.max-video-width {
  video {
    width: 100%;
    height: auto;
  }
}

// for filters ordered products list
mat-list.no-padding {
  .mat-list-item-content {
    padding: 0 !important;
  }
}
